import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Conditionally renders an installment payment message that lets the customer know they can pay for
 * their order using a supported installment payment vendor.
 */
class InstallmentPaymentMessage extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-estimated-total'];
  }

  readonly dataset!: {
    /**
     * The currency code, e.g. "CAD"
     */
    currencyCode: string;

    /**
     * The currency symbol, e.g. "$"
     */
    currencySymbol: string;

    /**
     * Estimated total price in cents
     */
    estimatedTotal: string;

    /**
     * Url for the installment payment afterpay logo asset
     */
    logoUrlAfterpay: string;

    /**
     * Url for the installment payment Klarna logo asset
     */
    logoUrlKlarna: string;

    /**
     * The threshold from which installment payment is possible
     */
    threshold: string;
  };

  public shadowRoot!: ShadowRoot;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();

    const learnMoreButton = this.shadowRoot.querySelector('button');
    learnMoreButton.addEventListener('click', onButtonClick);
  }

  public disconnectedCallback() {
    const learnMoreButton = this.shadowRoot.querySelector('button');
    learnMoreButton?.removeEventListener('click', onButtonClick);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    this.renderInstallments();
    this.renderLogo();
    this.renderMessages();
  }

  private renderInstallments() {
    const estimatedTotal = parseInt(this.dataset.estimatedTotal, 10);
    type PriceElement = HTMLElementTagNameMap['price-element'];
    const totalElement = <PriceElement>this.shadowRoot.getElementById('total');
    if (totalElement) {
      totalElement.dataset.currencyCode = this.dataset.currencyCode;
      totalElement.dataset.currencySymbol = this.dataset.currencySymbol;

      if (Number.isInteger(estimatedTotal)) {
        totalElement.dataset.cents = (estimatedTotal / 4).toString();
      } else {
        totalElement.removeAttribute('data-cents');
      }
    } else {
      console.log('could not find total element to update');
    }
  }

  private renderLogo() {
    const klarnaLogos = this.shadowRoot.querySelectorAll<HTMLImageElement>('.logo-klarna');
    for (const logo of klarnaLogos) {
      logo.src = this.dataset.logoUrlKlarna;
    }

    const afterpayLogos = this.shadowRoot.querySelectorAll<HTMLImageElement>('.logo-afterpay');
    for (const logo of afterpayLogos) {
      logo.src = this.dataset.logoUrlAfterpay;
    }
  }

  private renderMessages() {
    // render installments and minimum requirements messages
    const estimatedTotal = parseInt(this.dataset.estimatedTotal, 10);
    const threshold = parseInt(this.dataset.threshold, 10);
    const isEligible = Number.isInteger(estimatedTotal) && estimatedTotal > threshold;

    // TODO: we continue to see a rare error where the installments element is not found. i added
    // optional chaining to mitigate the error. however, that is just a short term fix. investigate
    // why the element is sometimes not found. either explain why in a comment, or fix the actual
    // issue causing the error.

    const installmentsElement = this.shadowRoot.getElementById('installments');
    const minReqElement = this.shadowRoot.getElementById('minimum-requirement');
    if (isEligible) {
      installmentsElement?.classList.remove('hidden');
      minReqElement.classList.add('hidden');
    } else {
      installmentsElement?.classList.add('hidden');
      minReqElement.classList.remove('hidden');
    }
  }
}

function onButtonClick(_event: MouseEvent) {
  type Detail = WindowEventMap['cart-close-requested']['detail'];
  const cartCloseEvent = new CustomEvent<Detail>('cart-close-requested',
    { detail: { animate: false } }
  );
  dispatchEvent(cartCloseEvent);

  dispatchEvent(new Event('installment-payment-modal-requested'));
}

declare global {
  interface HTMLElementTagNameMap {
    'installment-payment-message': InstallmentPaymentMessage;
  }
}

if (!customElements.get('installment-payment-message')) {
  customElements.define('installment-payment-message', InstallmentPaymentMessage);
}
